// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { Nullable } from '@/_typings/types/nullable'
import Localization from '@/utils/localization'
import LabelC from '@/components/controls/label-c/index.vue'

export default defineComponent({
  name: 'number-input',
  components: {
    LabelC
  },
  props: {
    decimalDigits: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    errorText: { type: String, default: null },
    incomplete: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    maximumValue: { type: Number, default: null },
    minimumValue: { type: Number, default: null },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    label: { type: String, default: null },
    validatorKey: { type: Number, default: 0 },
    value: { type: String, default: null }
  },
  emits: [ 'input', 'change' ],
  data() {
    return {
      displayValue: null as Nullable<string>,
      returnValue: null as Nullable<string>,
      requireValidator: true,
      valueValidator: true
    }
  },
  created() {
    this.displayValue = Localization.formatNumber(Localization.getUniversalNumber(this.value), this.decimalDigits)
  },
  methods: {
    changeValue() {
      if (this.valueValidator) {
        this.$emit('change', Number(this.returnValue))
        this.displayValue = Localization.formatNumber(Localization.getUniversalNumber(this.returnValue), this.decimalDigits)
      }
    },
    inputValue(e: any) {
      this.displayValue = e.target.value
      this.returnValue = Localization.getUniversalNumber(e.target.value)
      this.valueValidator = this.validateValue(this.returnValue)
      if (this.valueValidator && this.decimalDigits !== null) {
        this.returnValue = this.returnValue || this.returnValue === '0' ? String(Number(Math.round(Number(this.returnValue + 'e' + this.decimalDigits)) + 'e-' + this.decimalDigits)) : null
      }
      this.$emit('input', this.returnValue)
    },
    validateValue(value: any): boolean {
      const isNumber = !isNaN(value)
      return isNumber &&
        (this.minimumValue === null || Number(value) >= this.minimumValue) &&
        (this.maximumValue === null || Number(value) <= this.maximumValue)
    }
  },
  watch: {
    validatorKey(value) {
      this.requireValidator = value === 0 ? true : !this.required || Boolean(this.value)
      this.valueValidator = value === 0 ? true : this.validateValue(Localization.getUniversalNumber(this.value))
    },
    value() {
      this.displayValue = Localization.formatNumber(Localization.getUniversalNumber(this.value), this.decimalDigits)
      this.requireValidator = true
      this.valueValidator = true
    }
  }
})

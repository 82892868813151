// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import { defineComponent } from 'vue'
import LabelC from '@/components/controls/label-c/index.vue'
import Flatpickr from 'vue-flatpickr-component'
import Localization from '@/utils/localization'
import 'flatpickr/dist/flatpickr.css'
import { Nullable } from '@/_typings/types/nullable'

export default defineComponent ({
  name: 'date-picker',
  props: {
    disabled: { type: Boolean, default: false },
    incomplete: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    value: { type: String, default: null }
  },
  components: {
    LabelC,
    Flatpickr
  },
  data() {
    return {
      textFieldRect: null,
      date: null as Nullable<string>,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M J Y',
        altInput: true,
        formatDate: Localization.formatDateUTC,
        // dateFormat: 'Z'
        dateFormat: 'Y-m-d'
      },                
    }
  },
  methods: {
    changeDateValue(e) {
      this.$emit('change-date', e.length ? e[0] instanceof Date ? e[0].toISOString() : e[0] : null)
    }
  },
  watch: {
    value() {
      this.date = this.value && this.value.includes('T') ? this.value.split('T')[0] : this.value
    }
  }
})
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_label_c = _resolveComponent("label-c")!
  const _component_flatpickr = _resolveComponent("flatpickr")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-picker", { 'invalid': _ctx.invalid, 'incomplete': _ctx.incomplete, 'required': _ctx.required, 'read-only': _ctx.readonly, 'disabled': _ctx.disabled }]),
    "data-sel": "date-picker"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_label_c, {
          key: 0,
          disabled: _ctx.disabled,
          required: _ctx.required
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["disabled", "required"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_flatpickr, {
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
        readonly: _ctx.readonly,
        tabindex: _ctx.disabled ? -1 : 0,
        config: _ctx.config,
        name: 'datepicker-' + _ctx.label,
        onOnChange: _ctx.changeDateValue
      }, null, 8, ["modelValue", "readonly", "tabindex", "config", "name", "onOnChange"])
    ])
  ], 2))
}
<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="number-input" :class="{ 'invalid': readonly && (invalid || !requireValidator || !valueValidator), 'incomplete': incomplete, 'required': required, 'read-only': readonly }">
    <label-c v-if="label" :disabled="disabled">{{ label }}</label-c>
    <div class="input-group">
      <input type="text"
        class="form-control"
        :aria-label="label"
        :disabled="disabled"
        :readonly="readonly"
        :tabindex="disabled ? -1 : 0"
        :value="displayValue"
        @input="inputValue($event)"
        @change="changeValue()"
        @keydown.enter.prevent.stop="changeValue()"
      >
    </div>
    <template v-if="!readonly">
      <span v-if="!requireValidator" class="error-message">{{ $t('required-value_message') }}</span>
      <span v-if="!valueValidator" class="error-message">{{ errorText || $t('invalid-value_message') }}</span>
    </template>
  </div>
</template>
<script src="./number-input.ts"></script>
<style src="./number-input.scss" lang="scss"></style>

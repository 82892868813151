// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import Localization from '@/utils/localization'
import showConfirmationModal from '@/utils/showConfirmationModal'
import { Nullable } from '@/_typings/types/nullable'

export default defineComponent({
  name: 'configurator-cart',
  Localization,
  props: {
    activeConfigurationIds: { type: Object as PropType<any>, default: null },
    cart: { type: Object as PropType<any>, default: () => { return {} } },
    isVisible: { type: Boolean, default: true }
  },
  emits: [ 'close-cart', 'configure-configuration', 'ready-cart', 'save-cart' ],
  data() {
    return {
      cartDrawerClickHandler: (e: any) => {},
      configuratorUrl: null as Nullable<string>,
      hasUnsavedChanges: false
    }
  },
  created() {
    this.constructConfiguratorUrl()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage, false)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage)
  },
  unmounted() {
    document.documentElement.style.overflow = ''
  },
  computed: {
    ...mapGetters({
      runtimeSessionId: 'getRuntimeSessionId'
    })
  },
  methods: {
    ...mapMutations([
      'setRuntimeSessionId'
    ]),
    constructConfiguratorUrl() {
      this.configuratorUrl = this.cart.dealId && this.cart.cartId
        ? window.KUBE_SETTINGS.CPQResellerConfiguratorURL
          + `/deals/${this.cart.dealId}/carts/${this.cart.cartId}/cart`
          + (this.runtimeSessionId(this.cart.cartId) ? '?sessionId=' + this.runtimeSessionId(this.cart.cartId) : '')
        : null
    },
    hideDrawer(ignoreUnsavedChanges: boolean = false) {
      const closeCart = () => {
        this.hasUnsavedChanges = false
        this.$emit('close-cart')
        this.$el.classList.add('slide-out')
        this.$el.classList.remove('slide-in')
        setTimeout(() => {
          document.documentElement.style.overflow = ''
        }, 400)
        const app = document.getElementById('app')
        if (app) app.removeEventListener('click', this.cartDrawerClickHandler)  
      }
      if (this.hasUnsavedChanges && !ignoreUnsavedChanges && !this.activeConfigurationIds) {
        showConfirmationModal(confirmationTypes.UNSAVED_CHANGES, () => {
          this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'REFRESH-SESSION', reconfigureCart: true }, '*')
          closeCart()
        })
      } else {
        closeCart()
      }
    },
    receiveMessage(e) {
      switch (e.data.type) {
        case 'CONFIGURATOR-READY':
          this.setRuntimeSessionId({ key: this.cart.cartId, value: e.data.sessionId })
          this.$emit('ready-cart')
          break
        case 'CONFIGURATOR-CONFIGURE-ITEM':
          this.$emit('configure-configuration', { rootConfigurationId: e.data.rootConfigurationId, configurationId: e.data.configurationId })
          break
        case 'CONFIGURATOR-CART-SAVED':
          this.$emit('save-cart')
          this.$emit('close-cart')
          break
        case 'CONFIGURATOR-CART-UNSAVED-CHANGES':
          this.hasUnsavedChanges = Boolean(e.data.hasUnsavedChanges)
          break
        default:
          break
      }
    },
    saveCart() {
      this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'SAVE-CART' }, '*')
    },
    showDrawer() {
      this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'SET-ACTIVE-CONFIGURATION-IDS', activeConfigurationIds: this.activeConfigurationIds ? JSON.parse(JSON.stringify(this.activeConfigurationIds)) : null }, '*')
      this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'REFRESH-SESSION' }, '*')
      this.$el.classList.remove('slide-out')
      this.$el.classList.add('slide-in')
      document.documentElement.style.overflow = 'hidden'
      const app = document.getElementById('app')
      if (app) {
        app.addEventListener('click', this.cartDrawerClickHandler)
        this.cartDrawerClickHandler = (e) => {
          const clickInsideDrawer = e.path
            .map(path => path.className)
            .some(className => className && (className.includes('configurator-cart')))
          if (!clickInsideDrawer) this.hideDrawer()
        }
      }
    }
  },
  watch: {
    cart(newValue, oldValue) {
      if (newValue.dealId !== oldValue.dealId && newValue.cartId !== oldValue.cartId) this.constructConfiguratorUrl()
    },
    isVisible(value) {
      if (value) this.showDrawer(); else this.hideDrawer(true)
    }
  }
})

// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { Nullable } from '@/_typings/types/nullable'
import JQuery from 'jquery'

export default defineComponent({
  data() {
    return {
      isVisibleCartDrawer: null as Nullable<boolean>
    }
  },
  methods: {
    hideDrawer() {
      this.isVisibleCartDrawer = false
    },
    showDrawer() {
      JQuery(function () { JQuery('.dropdown-menu.show').removeClass('show') })
      this.isVisibleCartDrawer = true
    }
  }
})